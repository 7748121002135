import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from 'prop-types'
import Gallery from '../../../plugins/gallery'

const GallerySaruhashi = ({ idxSection, language }) => {
    const data = useStaticQuery(graphql`
    query {
        allFile(filter: {extension: {regex: "/(jpg)|(png)|(gif)/"}, relativeDirectory: {eq: "stations/otsuki/saruhashi"}}) {
            edges {
                node {
                    childImageSharp {
                        fluid(maxHeight: 1000, maxWidth: 1000, srcSetBreakpoints: [576, 768, 992, 1200]) {
                            src
                            aspectRatio
                            ...GatsbyImageSharpFluid
                        }
                    }
                    base
                }
            }
        }
      stationsJson(pages: {zh_hant: {sections: {elemMatch: {gallery: {directory: {eq: "stations/otsuki/saruhashi"}}}}}}) {
        pages {
          zh_hant {
            sections {
              gallery {
                directory
                items {
                  fileName
                  title
                }
              }
            }
          }
        }
      }
    }
  `)
    const images = data.allFile.edges.map(edge => edge.node.childImageSharp.fluid.src);
    const thumbs = data.allFile.edges.map(edge => edge.node.childImageSharp.fluid);
    const captions = data.stationsJson.pages[language].sections[idxSection].gallery.items.map(item => item.title);

    return (
        <Gallery images={images} thumbs={thumbs} captions={captions} />
    )
}

GallerySaruhashi.propTypes = {
    idxSection: PropTypes.number,
    language: PropTypes.string,
}

export default GallerySaruhashi